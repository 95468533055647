import { useEffect, useState } from "react";
import { get } from "../api/apiHelper";
import { endpoints } from "../api/endpoints";

export const useProfile = (authInfo) => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (!authInfo) return;

    //getUserProfile
    get(endpoints.account.getUserProfile, authInfo, onUserProfileSuccess);
    function onUserProfileSuccess(res) {
    //   console.log("res?.data?.data", res?.data?.data);
      setProfile(res?.data?.data);
    }
  }, []);

  return { profile };
};
