import axios from "axios";

let baseUrl = "https://mmcg.tk4m.com/v1";

export const get = (
  endpoint,
  params,
  onSuccess,
  onError = undefined,
  customBaseUrl = undefined
) => {
  if (customBaseUrl) {
    baseUrl = customBaseUrl;
  }

  return axios
    .get(baseUrl + endpoint + params)
    .then(function (response) {
      // console.log(response);
      onSuccess(response);
    })
    .catch(function (error) {
      // console.log(error);
      if (onError !== undefined) {
        onError(error);
      }
    });
};

export const post = (
  endpoint,
  data,
  onSuccess,
  onError = undefined,
  customBaseUrl = undefined
) => {
  if (customBaseUrl) {
    baseUrl = customBaseUrl;
  }

  return axios
    .post(baseUrl + endpoint, data)
    .then(function (response) {
      // console.log(response);
      onSuccess(response);
    })
    .catch(function (error) {
      // console.log(error);
      if (onError !== undefined) {
        onError(error);
      }
    });
};
