import coin from '../../assets/images/coin.png';
import topbarBg from '../../assets/images/topbarBg.webp';
import en from "../../assets/images/us.png";
import mm from "../../assets/images/mm.png";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useProfile } from '../../hooks/useProfile';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import navbarBg from '../../assets/images/navbarBg.webp';
import { Button, ButtonBase, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import { get } from '../../api/apiHelper';
import { endpoints } from '../../api/endpoints';
import Loader from '../../loader';
import Alert from '../../alert';
import { useLanguage } from '../../context';
import { formatAmount, t } from '../../utils';
import Marquee from "react-fast-marquee";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
    color: "#000"
};

const marqueeStyle = {
    fontSize: 'small',
    lineHeight: '30px',
    color: '#fff',
    fontFamily: 'inherit'
}


export const Header = () => {

    const [open, setOpen] = useState(false);
    const [checkModalOpen, setCheckModalOpen] = useState(false);
    const [deepLink, setDeepLink] = useState();
    const [denos, setDenos] = useState([]);
    const [selectedDeno, setSelectedDeno] = useState();
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const { isLoggedIn, authInfo } = useContext(AuthContext);
    const { profile } = useProfile(authInfo);
    const { language, changeLanguage } = useLanguage();
    const [walletAmount, setWalletAmount] = useState({
        current: 0,
        client: 0
    });
    const [formData, setFormData] = useState({
        activeTnxType: 0,
        amount: "",
        amountError: null,
    });


    useEffect(() => {
        // console.log("formData", formData);
    }, [formData])

    useEffect(() => {
        getDenos();
        getDeepLink();
    }, [])



    useEffect(() => {
        if(profile?.amount == 0){
            setOpen(true);
        }
        if (profile?.amount) {
            setWalletAmount((prev) => ({ ...prev, current: profile.amount }))
        }
    }, [profile]);


    const getDeepLink = () => {
        get(endpoints.account.getUserProfile, authInfo, onGetCurrentAmountSuccess);
        function onGetCurrentAmountSuccess(res) {
            const dl = res?.data?.data?.back_deep_link ?? "/";
            setDeepLink(dl)
        }
    }

    const getDenos = () => {
        get(endpoints.account.getClientDenos, authInfo, onGetDenos);
        function onGetDenos(res) {
            const denos = res?.data?.data?.denos
            if(denos) {
                setDenos(denos.split(","))
                setSelectedDeno(denos.split(",")[0])
            }
        }
    }


    const getWalletAmount = () => {
        get(endpoints.account.getUserProfile, authInfo, onGetCurrentAmountSuccess);
        function onGetCurrentAmountSuccess(res) {
            const currentAmount = res?.data?.data?.amount || 0;
            setWalletAmount((prev) => ({ ...prev, current: currentAmount }));
        }

        get(endpoints.wallet.getUserAmountFromClientWallet, authInfo, onGetClientAmountSuccess);
        function onGetClientAmountSuccess(res) {
            const clientAmount = res?.data?.data?.amount || 0;
            setWalletAmount((prev) => ({ ...prev, client: clientAmount }));
        }
    }


    const handleOpen = () => {
        setOpen(true);
        getWalletAmount();
    };

    const handleCheckModalOpen = () => setCheckModalOpen(true)

    const handleGoBack = () => {
        if (deepLink) {
            window.location.href = deepLink;
        } else {
            window?.ReactNativeWebView?.postMessage('Close lobby');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCheckModalClose = () => setCheckModalOpen(false);


    const handleSubmit = () => {
        // if (!formData.amount || formData.amount == null || formData.amount == 0) {
        //     setFormData((prev) => ({ ...prev, amountError: "ငွေပမာဏ ထည့်ပါ" }));
        //     return;
        // }
        if ((formData.activeTnxType == 0 && selectedDeno > walletAmount.client) || (formData.activeTnxType == 1 && selectedDeno > walletAmount.current)) {
            setErrorMsg({ code: 500, msg: t('youDontHaveSufficientBalance',language)});
            setOpenAlert(true);
            return;
        }

        setModalLoading(true);

        setFormData((prev) => ({ ...prev, amountError: null }));
        const amount = formData.activeTnxType == 0 ? selectedDeno : -selectedDeno;
        const params = authInfo + `&amount=${amount}`;
        let action;
        if (amount > 0) action = "Deposit"
        if (amount < 0) action = "Withdraw"

        get(endpoints.wallet.userDepositAndWithdraw, params, onGetClientAmountSuccess);
        function onGetClientAmountSuccess({ data: res }) {

            let msg;
            if (res.code == 200) {
                getWalletAmount();
                msg = "Successfully " + action;
                setOpen(false);
            } else {
                msg = res.message
            }

            setFormData((prev) => ({ ...prev, amount: "" }));
            setModalLoading(false);
            setErrorMsg({ code: res.code, msg: msg });
            setOpenAlert(true);
        }

    }

    const handleWithdraw = () => {
        if(walletAmount.current == 0) return setCheckModalOpen(false);
        setModalLoading(true);

        const amount = walletAmount.current ? Number(walletAmount.current) * -1 : 0;
        const params = authInfo + `&amount=${amount}`;
        let action = "Withdraw";

        get(endpoints.wallet.userDepositAndWithdraw, params, onGetClientAmountSuccess);
        function onGetClientAmountSuccess({ data: res }) {
            let msg;
            if (res.code == 200) {
                getWalletAmount();
                msg = "Successfully " + action;
            } else {
                msg = res.message;
            }
            setModalLoading(false);
            setErrorMsg({ code: res.code, msg: msg });
            setOpenAlert(true);
            setCheckModalOpen(false);
        }
    }

    const handleCloseAlert = () => setOpenAlert(false)

    return (
        <>

            <div className='container mx-auto'>
                <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert} />
                <div style={{ backgroundImage: `url(${topbarBg})`, backgroundSize: "cover" }} className='flex flex-row justify-between items-center p-3'>
                    {/* <img src={profile?.clientLogo} style={{ width: 60, height: 'auto', background: "white" }} /> */}
                    <IconButton color='white' onClick={handleGoBack} style={{ width: "50px", height: "50px" }} sx={{ '&:hover': { opacity: "0.8" } }}>
                        <ArrowBackIcon style={{ width: "2.2rem", height: "2.2rem", color: "white" }} />
                    </IconButton>

                    <div className="">
                        {
                            isLoggedIn && (
                                <div className='flex flex-row items-center gap-3'>
                                    <ButtonBase className='flex flex-row items-center gap-2 !px-2 !py-2 !rounded-2xl' onClick={handleOpen} style={{ backgroundColor: "#198754" }}>
                                        {/* <AddRoundedIcon style={{fontSize: "14px"}}/> */}
                                        <span className='font-semibold text-sm font-bodyen'>{t("deposit", language)}</span>
                                    </ButtonBase>
                                    <ButtonBase className='flex flex-row items-center gap-2 !px-2 !py-2 !rounded-2xl' onClick={handleCheckModalOpen} style={{ backgroundColor: "#DC3545" }}>
                                        {/* <RemoveRoundedIcon style={{fontSize: "14px"}}/> */}
                                        <span className='font-semibold text-sm font-bodyen'>{t("withdraw", language)}</span>
                                    </ButtonBase>
                                    <ButtonBase className='flex flex-row items-center gap-2 !px-2 !py-1 !rounded-2xl'  style={{ backgroundColor: "rgba(255,255,255,0.4)" }}>
                                        <img src={coin} alt="" width={20} />
                                        <span className='font-semibold text-sm font-bodyen'>{formatAmount(walletAmount.current)}</span>
                                        {/* <a href="#" ><AddCircleOutline /></a> */}
                                    </ButtonBase>
                                    <a className='hover:cursor-pointer' onClick={()=>changeLanguage( language == "mm" ? "en" : "mm" )}>
                                        <img src={language == "mm" ? en : mm} width={30} />
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className='container mx-auto' style={marqueeStyle}>
                <Marquee>
                    {t("rule", language)} 
                </Marquee>
            </div>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <div className='flex flex-row justify-center gap-4 items-center  px-4 py-4' style={{ background: `url(${navbarBg})`, backgroundSize: "cover" ,position: "relative"}}>
                    <ButtonBase style={{ width: "50px", height: "50px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => handleClose()}>
                        <ClearRoundedIcon style={{ width: "2rem", height: "2rem", color: 'lightsteelblue' }} />
                    </ButtonBase>
                        {
                            (profile?.accountType === "third-party") ? (
                                <>
                                    <div className='shadow border  w-6/12 p-3 rounded-md  text-center bg-white'>
                                        <h4 className='text-sm'>Game Wallet</h4>
                                        <div className=' font-bold'>{formatAmount(walletAmount.current) ?? 0}</div>
                                    </div>
                                    {/* <CompareArrowsIcon fontSize='large' style={{ color: 'white' }} />
                                    <div className='shadow border w-6/12 p-3 rounded-md   text-center bg-white'>
                                        <h4 className='text-sm'>Your Wallet</h4>
                                        <div className=' font-bold'>{formatAmount(walletAmount.client) ?? 0}</div>
                                    </div> */}
                                </>
                            ) : (
                                <h1 className=' text-white text-2xl font-bold'>ကြိုဆိုပါတယ်။</h1>
                            )
                        }

                    </div>


                    {
                        (profile?.accountType === "third-party") ? (

                            <>
                                <div className='px-4 py-2 flex' >
                                    <WarningAmberRoundedIcon style={{color: "red",fontSize: "18px"}}/>
                                    <p className='text-sm leading-6' style={{color: "red"}}>{ t("walletDepositRule", language)}</p>
                                </div>

                                <div className='px-4 pb-4'>
                                    {/* <RadioGroup
                                        row
                                        value={formData.activeTnxType}
                                        onChange={(event) => setFormData((prev) => ({ ...prev, activeTnxType: (event.target).value }))}
                                    >
                                        <FormControlLabel sx={{ ".css-ahj2mt-MuiTypography-root": { fontFamily: "inherit" } }} value={0} control={<Radio />} label={t("deposit", language)} />
                                        <FormControlLabel sx={{ ".css-ahj2mt-MuiTypography-root": { fontFamily: "inherit" } }} value={1} control={<Radio />} label={t("withdraw", language)} />
                                    </RadioGroup> */}
                                    {/* <TextField
                                        error={formData.amountError ? true : false}
                                        fullWidth
                                        type="number"
                                        label={t("enterAmount", language)}
                                        placeholder={t("enterAmount", language)}
                                        helperText={formData.amountError}
                                        className='!mt-3 font-bodyen'
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                        value={formData.amount}
                                        sx={{ ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": { fontFamily: "inherit" }, ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": { fontFamily: "inherit" }, ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": { fontFamily: "inherit" } }}
                                        onChange={(event) => setFormData((prev) => ({ ...prev, amount: (event.target).value }))}
                                    /> */}
                                    <div style={{display: 'flex',justifyContent: "space-evenly",padding: "0.3rem 1rem",flexDirection: "column"}}>
                                        {
                                           denos.map(amount => {
                                            return (
                                                <ButtonBase key={amount} onClick={() => setSelectedDeno(amount)} style={{display: "flex",flexDirection: "row",alignItems: "center",margin: "0.5rem 0",padding: '0.6rem 1.2rem', borderRadius: "0.3rem",backgroundColor: selectedDeno == amount ? "#198754" : "",color: selectedDeno == amount ? "white" : "black",boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",borderWidth: selectedDeno == amount ? "1.5px" : 0 ,borderStyle: "solid",borderColor: selectedDeno == amount ? "#198754" : ""}}>
                                                    <img src={coin} alt="" width={20} style={{marginRight: "0.5rem"}} />
                                                    <h3 style={{margin: 0,display: "flex",fontFamily: "sans-serif"}}>{Number(amount).toLocaleString()}</h3>
                                                </ButtonBase>
                                            )
                                           })
                                        }
                                    </div>
                                    <div className='flex flex-row justify-end gap-3 mt-4'>
                                        {/* <Button variant="outlined" onClick={handleClose} style={{ width: "90px", height: "36px", fontFamily: "inherit" }}>{ t("cancel", language)}</Button> */}

                                        <Button variant="contained" onClick={handleSubmit} style={{ width: "100px", height: "36px", fontFamily: "inherit",backgroundColor: "#198754" }}>
                                            {
                                                modalLoading ?
                                                    <Loader btn />
                                                    :
                                                    t("doDeposit", language)
                                            }
                                        </Button>

                                    </div>
                                </div>
                            </>

                        ) : (

                            <div className='p-4'>
                                <p className='text-xs'>Coming Soon</p>
                            </div>

                        )
                    }
                </Box>
            </Modal>
            <CheckModal checkModalOpen={checkModalOpen} handleCheckModalClose={handleCheckModalClose} language={language} t={t} handleWithdraw={handleWithdraw} modalLoading={modalLoading} profile={profile}/>

        </>
    );
};

function CheckModal({checkModalOpen, handleCheckModalClose, language, t, handleWithdraw, modalLoading, profile}) {
  return (
        <Modal
                open={checkModalOpen}
                onClose={handleCheckModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ButtonBase style={{ width: "50px", height: "50px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => handleCheckModalClose()}>
                        <ClearRoundedIcon style={{ width: "2rem", height: "2rem", color: 'lightsteelblue' }} />
                    </ButtonBase>

                    <div className='flex flex-row justify-between gap-4 items-center  px-4 py-4' style={{ background: `url(${navbarBg})`, backgroundSize: "cover" }}>
                        <h1 className=' text-white text-2xl font-bold'>{ t("areYouSure", language)}</h1>
                    </div>
                    <>

                    <div className='px-4 py-2 flex ' >
                        <WarningAmberRoundedIcon style={{color: "red",fontSize: "18px"}}/>
                        <p className='text-sm leading-6' style={{color: "red"}}>{ t("walletWithdrawRule", language)}</p>
                    </div>

                    { 
                      (profile?.accountType === "third-party") ? (
                        <div className='px-4 pb-4'>
          
                            <div className='flex flex-row justify-end gap-3 mt-4'>
                                {/* <Button variant="outlined" onClick={handleCheckModalClose} style={{ width: "95px", height: "36px", fontFamily: "inherit" }}>{ t("cancel", language)}</Button> */}

                                <Button variant="contained" onClick={handleWithdraw} style={{ width: "100px", height: "36px", fontFamily: "inherit",backgroundColor: "#DC3545" }}>
                                    {
                                        modalLoading ?
                                            <Loader btn />
                                            :
                                        t("doWithdraw", language)
                                    }
                                </Button>

                            </div>
                        </div>

                        ) : (

                        <div className='p-4'>
                            <p className='text-xs'>Coming Soon</p>
                        </div>
                      )
                    }
                    </>

                        
                    



                </Box>
            </Modal>
  )
}


