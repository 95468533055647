import { useLanguage } from "../../context"
import { t } from "../../utils";

export const Footer = () => {

    const { language, changeLanguage } = useLanguage();

    return (
        <div className="container mx-auto">
            <div className=" bg-surface p-5 mt-1">
                <p className="text-center text-sm font-bold">
                    {t("copyright", language)}
                </p>
            </div>
        </div>
    )
}