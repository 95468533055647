 /** @jsxImportSource @emotion/react */
import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { css} from '@emotion/react';

export default function Alert({openAlert,errorMsg,handleCloseAlert}) {

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  return (
    <Snackbar autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right"}}  open={openAlert}  onClose={handleCloseAlert} >
    <Alert style={{fontSize: "14px",display: "flex",justifyContent: "center",alignItems: "center"}}  onClose={handleCloseAlert} severity={errorMsg.code != 200 ? (errorMsg.info ? "info" : "error") : "success"}
      css={css`.css-ki1hdl-MuiAlert-action{padding: 0 0 0 0.6rem !important;font-family: inherit}; `}
      sx={{ ".MuiAlert-message": { fontFamily: 'Noto Sans Myanmar, sans-serif' } }}
    >
      {errorMsg.msg}
    </Alert>
  </Snackbar>
  )
}
