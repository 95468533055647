import navbarBg from '../../assets/images/navbarBg.webp';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useCallback, useEffect, useState } from 'react';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Button, ButtonBase, Fade } from '@mui/material';
import Modal from '@mui/material/Modal';

import coin from '../../assets/images/coin.png';
import { useAuth } from '../../context/AuthContext';
import { get, post } from '../../api/apiHelper';
import { endpoints } from '../../api/endpoints';
import { useLanguage } from '../../context';
import { t } from '../../utils';
import Alert from '../../alert';
import Loader from '../../loader';
import { useProfile } from '../../hooks/useProfile';
import moment from 'moment';

const playStyle = {
    width: '2em',
    height: '2em',
    background: '#00000075',
    borderRadius: '100%',
    border: '5px solid #fff'
}

export const Home = () => {

    const [value, setValue] = useState(0);
    const { isLoggedIn, authInfo } = useAuth();
    const {language, changeLanguage} = useLanguage();
    const [categories, setCategories] = useState([]);
    const [events, setEvents] = useState([]);
    const { profile } = useProfile(authInfo);
    const [eventModalOpen,setEventModalOpen] = useState({show: false,event: {}});
    const [clientgames, setClientGames] = useState({
        isLoading: false,
        data: []
    });

    const [challengePromos, setChallengePromos] = useState({
        isLoading: false,
        data: []
    });

    const [cashbackPromo, setCashbackPromo] = useState({
        isLoading: false,
        data: {}
    });

    const handleChange = async (event, newValue) => {
        setClientGames(prevData=>({...prevData,isLoading : true}))
        await new Promise(resolve => setTimeout(resolve,500));
        setClientGames(prevData=>({...prevData,isLoading : false}))
        setValue(newValue);
    };

    const handleEventModalClose = async () => {
        setEventModalOpen({show: false,event: {}});
        await new Promise(resolve => setTimeout(resolve,100));
        const result = getEvent(events);
        if(result.code == 200){
            setEventModalOpen({show: true,event: result.data});
        }
    };

    useEffect(()=> {
        //getPromotions
        get(endpoints.public.getPromotions, authInfo, onGetPromoSuccess);
        function onGetPromoSuccess(res) {
            if(res.data.code != 200) return;
            setEvents(res?.data?.data);
            checkEvent(res?.data?.data);
            const result = getEvent(res?.data?.data);
            // console.log('result',result)
            if(result.code == 200){
                setEventModalOpen({show: true,event: result.data});
            }
        }
    },[])

    const checkEvent = (remoteEvents) => {

        const storedEvents = localStorage.getItem("events");
        let arr = [];
        const format = 'DD-MM-YYYY';
        const today = moment().startOf('day');
        const formattedToday = today.format(format);
        if(!storedEvents || storedEvents == "undefined"){
            arr = remoteEvents?.map(e => {
                const show = e?.first_enter == 1 ? false : true; //if first enter 1, event will be shown in first enter
                return {id: e?.id, firstEnter: e?.first_enter, isViewed: false, isShowed: show, date: formattedToday}
            });
        }else{ 
            const parsedStoredEvents = JSON.parse(storedEvents);
            // if the date is exceed today, reset
            const date = parsedStoredEvents[0].date;
            
            const isPassedToday = moment(date, format).isAfter(today);
            if(isPassedToday) {
                arr = remoteEvents?.map(e => {
                    const show = e?.first_enter == 1 ? false : true;
                    return {id: e?.id, firstEnter: e?.first_enter, isViewed: false, isShowed: show, date: formattedToday}
                });
            }else{
                remoteEvents.forEach(e => {
                    let index = parsedStoredEvents.findIndex(se => se.id == e.id);
                    if (index == -1) {
                        const show = e?.first_enter == 1 ? false : true;
                        arr.push({id: e?.id, firstEnter: e?.first_enter, isViewed: false, isShowed: show, date: date});
                    }else{
                        const {firstEnter,isViewed, isShowed, date} = parsedStoredEvents[index];
                        const show = (e?.first_enter == 1 && !isViewed) ? false : true;
                        arr.push({ id: e?.id, firstEnter, isViewed, isShowed: show, date});
                    }
                });
            }
        }
    
        const eventsArrStr = JSON.stringify(arr);
        localStorage.setItem("events", eventsArrStr);
    }

    const updateEvent = (action,id,value) => {
        const storedEvents = localStorage.getItem("events");
        const parsedStoredEvents = JSON.parse(storedEvents);
        if(action == "show"){
            const modifiedArr = parsedStoredEvents.map((e) => {
                if(e.id == id){
                    return {...e, isShowed : value}
                }else{
                    return e
                }
            })
            localStorage.setItem("events", JSON.stringify(modifiedArr));
        }

        if(action == "view"){
            const modifiedArr = parsedStoredEvents.map((e) => {
                if(e.id == id){
                    return {...e, isViewed : value}
                }else{
                    return e
                }
            })
            localStorage.setItem("events", JSON.stringify(modifiedArr));
        }
    }

    const getEvent = (eventsArr) => {
        const storedEvents = localStorage.getItem("events");
        if(!storedEvents) {
            return {
                code: 404,
                data: null,
            }
        }
        const parsedStoredEvents = JSON.parse(storedEvents);
        for (let index = 0; index < parsedStoredEvents.length; index++) {
            const e = parsedStoredEvents[index];
            if(!e.isShowed){
                updateEvent('show', e.id,true)
                return {code:200,data:eventsArr[index]};
            }
        }
        return {
            code : 404,
            data : null
        }
    }

    useEffect(() => {
        
        if (isLoggedIn && authInfo) {
            // getCategories
            get(endpoints.account.getCategories, authInfo, onCategorySuccess);
            function onCategorySuccess(res) {
                setCategories(res?.data?.data);
            }

            // getClientGames
            setClientGames((prev) => ({ ...prev, isLoading: true }));
            get(endpoints.account.getClientGames, authInfo, onClientGamesSuccess);
            async function onClientGamesSuccess(res) {
                setClientGames((prev) => ({ data: res?.data?.data, isLoading: false }));                
            }
        }
    }, [isLoggedIn, authInfo]);


    const renderDisplay = useCallback(() => {
        if(!categories) return;
        const currentCategoryId = categories[value]?.id?.toString();
        // console.log("currentCategoryId", currentCategoryId);

        if (clientgames?.data?.length > 0) {

            let displayElements = [];

            clientgames.data.forEach((gameItem) => {
                const category = gameItem?.category?.split(',');
                // console.log("category", category.includes(currentCategoryId));

                if (category.includes(currentCategoryId)) {
                    // console.log("show result");

                    displayElements.push(
                        <div key={gameItem?.id} className="relative rounded-md overflow-hidden">
                            <div style={{
                                backgroundImage: `url(${gameItem?.logo})`,
                                paddingTop: "110%",
                                backgroundSize: "cover",
                                backgroundPosition: "center"
                            }}></div>
                            <div className='absolute bottom-0 w-full top-0 flex flex-row items-end' style={{ background: `linear-gradient(rgba(0,0,0,0), #34339A)` }}>
                                <div className="w-full text-center">
                                    <ButtonBase href={gameItem?.link + authInfo} className='!absolute !bottom-1/2  ' style={{width: "50px",height: "50px",borderRadius: "25px",left: `calc(50% - 25px )`}}>
                                        <PlayArrowOutlinedIcon fontSize='medium' style={playStyle} />
                                    </ButtonBase>
                                    <div className='px-2 pb-4 flex flex-col justify-center'>
                                        <h1 className='py-1 font-bold' style={{fontSize: "18px"}}>{(language == 'mm') ? gameItem?.name_mm : gameItem?.name }</h1>
                                        <a href="#" className='py-2' style={{textDecoration: "underline"}}>{ t("demoPlay", language)}</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    );
                }
            });

            return displayElements;
        }

        return null;
    }, [clientgames?.data, value, language]);

    const handleEventIconClick = (eventLink, eventId) => {
        updateEvent('view', eventId,true)
        window.location.href = `${eventLink}${authInfo}`;
    }

    return (
        isLoggedIn && (
            <>
                <div style={{ backgroundImage: `url(${navbarBg})`, backgroundSize: "cover" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        textColor='inherit'
                        sx={{".css-1l14upw-MuiButtonBase-root-MuiTab-root": {fontFamily: "inherit"}}}
                    >
                        {categories?.length > 0 && categories.map((category) => (
                            <Tab label={<span className='font-bold' style={{ textShadow: `hsla(0,0%,100%,.7) 0 0 10px`,fontFamily: "inherit" }} >{(language == 'mm') ? category?.name_mm : category?.name }</span>} icon={<img src={category?.icon} width={30} />} key={category?.id} />
                        ))}
                    </Tabs>
                </div>
                    {clientgames.isLoading ?
                     <Loader/>
                     :
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 md:gap-6 gap-4 bg-surface p-5'>
                        {renderDisplay()}
                    </div>
                    }
                <div style={{position: "fixed",bottom: "20px",right: "10px",display: 'flex',flexDirection: 'column'}}>
                    {
                        events?.map(e => {
                            return (
                                e.link 
                                    && 
                                <ButtonBase onClick={()=> handleEventIconClick(e?.link,e?.id)} style={{width: 70, height: 70,marginBottom: "1rem",borderRadius: "25px"}}>
                                        <img src={e?.event_icon}/>
                                </ButtonBase>
                            )
                        })
                    }
                </div>
               
                <EventModal updateEvent={updateEvent} eventModalOpen={eventModalOpen} handleEventModalClose={handleEventModalClose} language={language} t={t} profile={profile} authInfo={authInfo}/>
            </>
        )

    )
}



function EventModal({ updateEvent, eventModalOpen, handleEventModalClose, language, t, authInfo}) {

    const format = ('DD-MM-YYYY');
    const event = eventModalOpen?.event;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        height: 300,
        // border: '2px solid #000',
        // boxShadow: 24,
        // p: 4,
        // color: "#000"
    };

    const handleLookClick = (link) => {
        updateEvent('view', event.id,true)
        window.location.href = `${link}${authInfo}`;
    }

    return (
              <Modal
                  open={eventModalOpen?.show}
                //   onClose={()=> handleEventModalClose(event?.promo_name)}
                  closeAfterTransition
                  style={{border: "none"}}
                  className='modal'
                  
              >
                <Fade in={eventModalOpen?.show}>
                  <div style={style} className="rounded-md" >
                    <ButtonBase style={{ width: "50px", height: "50px", borderRadius: "25px",position: 'absolute',top: 0,right: 0,zIndex: 900 }} sx={{ '&:hover': { opacity: "0.8" } }} onClick={() => handleEventModalClose(event?.promo_name)}>
                        <ClearRoundedIcon style={{ width: "2rem", height: "2rem", color: '#DC3545' }} />
                    </ButtonBase>
                    <div style={{width: "100%"}} key={event?.id} className="relative rounded-md overflow-hidden">
                        <div style={{
                            backgroundImage: `url(${event?.event_img})`,
                            paddingTop: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}></div>
                        <div className='absolute bottom-0 w-full top-0 flex flex-row'>
                            <div className="w-full text-center flex justify-center">
                                {/* <h3 style={{color: "gold",fontStyle: "italic",fontWeight: "bold",marginTop: "10px",top: '22px', fontSize: "1.2rem",width: "120px", height: "40px", fontFamily: "inherit"}}>
                                   {moment(event?.start_date).format(format)} {t("between", language)} {moment(event?.end_date).format(format)}
                                </h3> */}
                            </div>
                        </div>
                    </div>   
  
                    {event?.link && <Button variant="contained" onClick={()=> handleLookClick(event.link)}  style={{bottom: '22px', fontSize: "1rem",width: "120px", height: "40px", fontFamily: "inherit",backgroundColor: "#198754",display: "flex", marginLeft: "auto",marginRight: "auto"}}>
                        {t("look", language)}
                    </Button>}
                  </div>
                  </Fade>
              </Modal>
    )
}
