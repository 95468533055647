export const endpoints = {
    account: {
      getUserProfile: '/account/getUserProfile',
      getCategories: '/account/getCategories',
      getClientGames: '/account/getClientGames',
      getClientDenos: '/account/getClientDenos'
    },
    wallet : {
      getUserAmountFromClientWallet: '/wallet/getUserAmountFromClientWallet',
      userDepositAndWithdraw: '/wallet/userDepositAndWithdraw',
    },
    public: {
      getPromotions: '/public/getPromotions',
    },
    challengePromo: {
      getChallengePromo: '/challengePromo/getChallengePromo',
      buyChallengePromo: '/challengePromo/buyChallengePromo',
      claimChallengePromo: '/challengePromo/claimChallengePromo',
    },
    cashbackPromo: {
      getCashbackPromo: '/cashbackPromo/getCashbackPromo',
      claimCashbackPromo: '/cashbackPromo/claimCashbackPromo',
    }
};