import { createContext, memo, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export const AuthContext = createContext({});

export const AuthProvider = memo(({ children }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('userId');
  const token = searchParams.get('token');
  let authValue;
  
  if(userId && token){
    authValue = {
      isLoggedIn: true,
      authInfo: `?userId=${userId}&token=${token}`
    }
  }else{
    authValue = {
      isLoggedIn: false
    }
  }

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
});


export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
