export const translations = {
    en: {
      deposit: "Deposit",
      withdraw: "Withdraw",
      doDeposit: "Deposit",
      doWithdraw: "Withdraw",
      enterAmount: "Enter the amount",
      youDontHaveSufficientBalance: "You don't have sufficient balance",
      cancel: "Cancel",
      submit: "Submit",
      copyright: "Copyright © MMCG",
      demoPlay: "Demo",
      areYouSure: "Are you sure?",
      walletDepositRule: "You can deposit below amount",
      walletWithdrawRule: "All the credit will be withdrawn",
      rule: "To start playing with a new player account, you usually need to add credit. loss of credit; Do not play more than one game at a time as losses may occur. MMCG is ready to help in order to make the players happy and comfortable. Therefore, we need to pay special attention to follow the rules properly.",
      challengePromo: "Challenge Promo",
      cashbackPromo: "Cashback Promo",
      buy: "Buy",
      claim: "Claim",
      claim: "Look",
      challengePromoRule: "Only one day",
      cashbackPromoRule: "Player will be rewarded",
      challengePromoInfo: "Continuous Winning",
      between: "between",
      complete: "complete",
      look: "Look",
    },
    mm: {
      deposit: "သွင်း",
      withdraw: "ထုတ်",
      doDeposit: "သွင်းမယ်",
      doWithdraw: "ထုတ်မယ်",
      enterAmount: "ငွေပမာဏ ရိုက်ထည့်ပါ",
      youDontHaveSufficientBalance: "ငွေပမာဏ မလုံ‌လောက်ပါ",
      cancel: "ပယ်ဖျက်",
      submit: "ပြုလုပ်",
      copyright: "မူပိုင်ခွင့် © MMCG",
      demoPlay: "အစမ်းဆော့မယ်",
      areYouSure: "သေချာပါသလား",
      walletDepositRule: "အောက်တွင်ပြထားသော တန်ဖိုးအလိုက်ထည့်သွင်းနိုင်မှာဖြစ်ပါတယ်။",
      walletWithdrawRule: "Game Wallet ထဲတွင်ရှိသော credit အားလုံးကိုထုတ်ယူသွားမှာဖြစ်ပါတယ်။",
      rule: "ကစားသမားအကောင့်အသစ်ဖြင့်စတင်ကစားရန် ဉီးစွာ credit ဖြည့်သွင်းရန်လိုအပ်ပါသည်။ credit ပျောက်ခြင်း၊ ဆုံးရှုံးခြင်းများ ဖြစ်ပေါ်နိုင်တဲ့ အတွက် တစ်ချိန်တည်းတွင် ဂိမ်းတစ်ခုထက်ပို၍ မကစားသင့်ပါ။ ကစားသမားများပျော်ရွင် အဆင်ပြေစေရေးကိုဦးတည်ကာ MMCG မှကူညီရန်အသင့်ရှိနေပါတယ်။ ထို့ကြောင့် စည်းကမ်းချက်များကို စနစ်တကျလိုက်နာရန် အထူး မေတ္တာ ရပ်ခံအပ်ပါတယ်။",
      challengePromo: "စိန်ခေါ် ပရိုမိုရှင်း",
      cashbackPromo: "ငွေအမ်း ပရိုမိုရှင်း",
      buy: "ဝယ်မယ်",
      claim: "ယူမယ်",
      look: "ကြည့်မယ်",
      challengePromoRule: "တစ်နေ့တည်းသာ အကျုံးဝင်သည်",
      cashbackPromoRule: "Player will be rewarded",
      challengePromoInfo: "ဆက်တိုက်အနိုင်ရရှိမှု",
      between: "မှ",
      complete: "ပြီးဆုံး"
    },
  };
  