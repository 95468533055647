import { createContext, memo, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getFromLocalStorage, saveToLocalStorage } from "../utils";
import { translations } from "../assets/languages";

export const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("mm");

  function changeLanguage(lang) {
    setLanguage(lang);
    saveToLocalStorage("Lang", lang);
  }

  useEffect(()=>{
    const lang = getFromLocalStorage("Lang");
    if(lang && translations[lang]){
      setLanguage(lang);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within an LanguageProvider");
  }
  return context;
};
