/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react';
import { Box, CircularProgress } from '@mui/material'

export default function Loader({btn,color,cardSm}) {
  return (
    <Box sx={{ display: 'flex',justifyContent: "center",marginTop: `${btn ? "0rem": cardSm ? "2rem": '10rem'}`,marginBottom: `${btn ? "0rem": cardSm ? "2rem": '10rem'}`}}>
      <CircularProgress size={26}  style={{color: color || "white"}} />
    </Box>
  )
}
