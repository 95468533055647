import { translations } from "../assets/languages";

export const t = (key, lang) => {
  return translations[lang][key];
};

export const formatAmount = (number) => {
  if (number !== null) {
    let numString = number.toString();
    return numString.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key) => {
  const storedValue = localStorage.getItem(key);
  if (storedValue !== undefined) {
    return storedValue;
  } else {
    return null;
  }
};
